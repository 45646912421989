<template lang="">
  <div
    class="d-flex flex-column px-12 py-16"
    style="height: 86vh; justify-content: center"
  >
    <v-icon
      class="mx-auto mb-4"
      size="92"
      :color="sucesso ? 'rgba(18, 183, 106, 1)' : 'rgba(150, 65, 65)'"
    >
      {{ sucesso ? '$mdiCheckCircleOutline' : '$alertWarningtOutline' }}
    </v-icon>
    <p
      class="mx-auto text-center"
      style="font-size: 22px"
    >
      {{
        sucesso ? 'QR Code lido com sucesso!' : 'Não foi possível ler o QRCode!'
      }}
    </p>
  </div>
</template>
<script>
import EnvioMaterialService from '@/common/services/cadastros/EnvioMaterialService.js';

export default {
  props: ['id', 'qrcodeId'],
  data() {
    return {
      mostrarLayout: false,
      sucesso: false,
    };
  },
  mounted() {
    EnvioMaterialService.lerQRCode(this.id, this.qrcodeId)
      .then(() => {
        this.mostrarLayout = true;
        this.sucesso = true;
        setTimeout(() => {
          this.$router.push({
            name: 'roteiro-executar-envio-material-entrega-recebimento'
          });
        }, 5000);
      })
      .catch(() => {
        this.mostrarLayout = true;
        this.sucesso = false;
      });
  },
};
</script>